import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ua/chapter_5/sub_2/slide1';
import Slide2 from 'src/slides/desktop/ua/chapter_5/sub_2/slide2';
import WrapperMobile from 'src/slides/mobile/ua/chapter_5/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Битва, яка врятувала Європу і світ I Варшавська битва" lang="ua" description="Успіх під Варшавою забезпечив Європі мир на 20 років." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO
      title="Битва, яка врятувала Європу і світ I Варшавська битва"
      lang="ua"
      description="Успіх під Варшавою забезпечив Європі мир на 20 років."
    />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
